.menu-mobile {
  z-index: 10;
  position: fixed;
  top: 0;
  background: #FFFFFF;
  width: 100%;
  height: 40px;
  .container { 
    height: 100%;
    .menu-mobile-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .btn-menu {
        border: none;
        background: transparent;
        color: #{$text-color};
        transition: color .3s;
        &:hover {
          color: #{$primary-color};
        }
      }
    }
  }
  @media #{$large-and-up} {
    display: none;
  }
}

#nav-meatballs {
  position: fixed; 
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  background: #FFFFFF;
  transition: left .3s;
  overflow: scroll; 
  .section {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
  .tel{
    margin-bottom: 1rem;
  }
  .container{
    overflow: scroll; 
  }
  .bold{
    margin-bottom: 1rem;
    
    .material-icons{
      margin-right: 1rem;
    }
  }
}

.top-bar {
 
  @media #{$medium-and-down} {
    #infos-header {
      display: none;
    }
    .container {
      display: block;
      .main-nav {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        padding-top: 40px;
        background: #{$primary-color};
        transition: right .3s;
        ul {
          margin: auto;
          width: 90%;
          flex-direction: column;
          align-items: flex-end;
          li {
            a{
              color: #ffffff;
              padding-right: 0;
              &.selected {
                color: #{$secondary-color};
              }
            }
            &:hover {
              >
              a {
                color: #{$secondary-color};
              }
            }
            &.sous_menu {
              >
              a {
                color: #{$background-color};
                &::after {
                }
              }
              ul.dropdown {
                display: block;
                position: inherit;
                background: transparent;
                li {
                  a {
                    color: #{$background-color};
                  }
                }
              }
              &:hover {
                ul.dropdown {
                }
              }
            }
          }
        }
      }
    }
  }
  @media #{$large-and-up}{
    & {
      position: fixed;
      width: 100%;
      background: #{$background-color};
      z-index: 9999;
      top: 0;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .main-nav {
    position: relative;
    z-index: 1;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: baseline;
      margin: {
        right: (-1 * $gutter-width / 2);
        left: (-1 * $gutter-width / 2);
      }
      li {
          position: relative;
          list-style: none;
        a {
          display: block;
          padding: {
            top: 1rem;
            right: $gutter-width / 2;
            bottom: 1rem;
            left: $gutter-width / 2;
          }
          font-size: 1em;
          line-height: 1rem;
          color: #{$text-color};
          &.selected {
            color: #{$primary-color};
          }
        }
        &:hover {
          >
           a {
              color: #{$primary-color};
           }
        }
        &.sous_menu {
          >
            a {
              &::after {
                display: inline-block;
                margin: -.125em 0 0 .25em;
                content: '\e5cf';
                font-family: 'Material Icons';
                line-height: 0;
                vertical-align: middle;
              }
            }
          ul.dropdown {
            display: none;
            flex-direction: column;
            align-items: stretch;
            width: auto;
            min-width: 100%;
            margin: 0;
            padding: 0;
            background-color: #{$background-color};
            position: absolute;
            left: 0;
           
            li {
              a {
                text-align: right;
                padding: {
                  top: .5rem;
                  bottom: .5rem;
                  
                }
                font-size: .875em;
              }
            }
          }
          &:hover {
            ul.dropdown {
              display: flex;
            }
          }
        }
      }
    }
  }
}