@charset 'utf-8';

html {
  box-sizing: border-box;

  *, *:before, *:after {
    box-sizing: inherit;
  }
}

body {
  overflow-x: hidden;
  @media #{$small-and-down} {
    padding-top: 50px;
  }
}

a {
  color: inherit;
  .primary-text &,
  &.primary-text {
    &:hover {
      color: #{$primary-color-darken};
    }
  }
  .secondary-text &,
  &.secondary-text {
    &:hover {
      color: #{$secondary-color-darken};
    }
  }
  .accent-text &,
  &.accent-text {
    &:hover {
      color: #{$accent-color-darken};
    }
  }
  &.tel-important {
    font-size: 24px;
    font-weight: 600;
  }
}

main p {
  margin-bottom: 1rem;
  a {
    color: #{$secondary-color};
    &:hover {
      color: #{$secondary-color-darken};
    }
  }
}

address {
  font-style: normal;
}

.index-page {
  .no-index{
      display: none;
  }
}

// Tables
// =============================================================
table {
  td {
    vertical-align: baseline;
    padding: 3px 10px 3px 0px;
  }
  &.horaires {
    .today {
      font-weight: 700;
    }
  }
}

// Divider
// =============================================================
.divider {
    height: 1px;
    overflow: hidden;
    background-color: #{$secondary-color};
  }

// Images
// =============================================================
img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}

// Google Maps
// =============================================================
.map-container {
  iframe {
    display: block;
    width: 100%;
  }
}

// Responsive Videos
// =============================================================
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}

// Responsive Images
//==============================================================
@mixin img-size-cover($size) {
  display: block;
  width: auto !important;
  max-width: none !important;
  min-width: 1000%;
  height: auto;
  min-height: 1000%;
  margin: auto;
  position: absolute;
  top: -10000%;
  right: -10000%;
  bottom: -10000%;
  left: -10000%;
  transform: scale($size / 1000);
}

.image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  &.s-fit-content {
    height: 100%;
    img {
      @include img-size-cover(100);
    }
  }
  &.m-fit-content {
    @media #{$medium-and-up} {
      height: 100%;
      img {
        @include img-size-cover(100);
      }
    }
  }
  &.l-fit-content {
    @media #{$large-and-up} {
      height: 100%;
      img {
        @include img-size-cover(100);
      }
    }
  }
  &.xl-fit-content {
    @media #{$extra-large-and-up} {
      height: 100%;
      img {
        @include img-size-cover(100);
      }
    }
  }
  &.circle {
    &::after {
      display: block;
      width: 100%;
      height: 0 !important;
      padding-bottom: 100%;
      content: '';
    }
    img {
      @include img-size-cover(100);
    }
  }
}

// Icons style
// =============================================================
.material-icons {
  /*
  margin: {
    top: -.125em;
    right: .25em;
  }
  */
  line-height: 1;
  vertical-align: middle;

  &.left {
    float: left;
    margin-right: 1rem;
  }
  &.right {
    float: right;
    margin-left: 1rem;
  }
  &.tiny {
    font-size: 1rem;
  }
  &.small {
    font-size: 2rem;
  }
  &.medium {
    font-size: 4rem;
  }
  &.large {
    font-size: 6rem;
  }
  &.primary, &.secondary, &.accent, &.circle {
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75em;
    text-align: center;
  }
  &.primary, &.secondary, &.accent {
    color: #FFFFFF;
    border-radius: #{$button-radius};
  }
  &.circle {
    border-radius: 50%;
  }
  &.outlined {
    border: solid 1px;
    background-color: transparent !important;
    &.primary {
      color: #{$primary-color};
    }
    &.secondary {
      color: #{$secondary-color};
    }
    &.accent {
      color: #{$accent-color};
    }
  }
}

// Numéros de téléphone
// =============================================================
.tel {
  white-space: nowrap;
  @media #{$medium-and-up} {
    pointer-events: none;
    cursor: text;
  }
}



// Utility Classes
// =============================================================

ul.inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin: {
    right: (-1 * $gutter-width / 2);
    left: (-1 * $gutter-width / 2);
  }
  >
    li {
      padding: 0 $gutter-width / 2;
      list-style: none;
    }
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin: {
    right: (-1 * $gutter-width / 2);
    left: (-1 * $gutter-width / 2);
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
  .col {
    padding: 0 $gutter-width / 2;
  }
}
.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: {
    right: (-1 * $gutter-width / 2);
    left: (-1 * $gutter-width / 2);
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
  .col {
    padding: 0 $gutter-width / 2;
  }
}

.hide {
    display: none !important;
  }



  // List
//=======================================
ul{
  margin-left: 40px;
  &.list-nostyle{
    list-style-type: none;
    margin: 0;
  }
}



li{
  padding:3px 0px;
}


  
// Text Align
// ======================================
.left-align {
    text-align: left;
  }
.right-align {
    text-align: right
  }
.center, .center-align {
    text-align: center;
  }

.left {
    float: left !important;
  }
.right {
    float: right !important;
  }

// No Text Select
// =====================================
.no-select {
    user-select: none;
  }

.rounded {
    border-radius: #{$card-border-radius};
  }

.circle {
    border-radius: 50%;
  }

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

.truncate,
.truncate > p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.no-padding {
    padding: 0 !important;
  }

// Z-levels
// =============================================================

.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}
.hoverable {
  transition: box-shadow .25s;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.eval {
  margin-right: 15px;
  .material-icons {
    margin-right: -18px;
  }
}

// INFOS
// =============================================================

.bloc.section{
  &.bloc-infos{
  padding:25px 0px;
}
}

@media #{$medium-and-down} {
  .bloc-content{
    &.infos2{
      padding:20px 0px;
    }
    &.infos3{
      padding:20px 0px;
    }
  }

  .slick-list{
    overflow:visible!important;
  }
 
}

@media #{$large-and-up} {
 .bloc.bloc-infos{
   flex-wrap: nowrap !important;
    
  }
 
}

// CONTACT
// =============================================================
.contact-page {
  h1 {
    color: #{$background-color};
    font-size: 2rem;
  }
  p.intro {
    color: #{$primary-color};
    span {
      color: #{$background-color};
    }
  }
  #fil-ariane {
    color: #{$background-color};
    a {
      color: #{$primary-color};
    } 
  }
  .coordonnees {
    li {
      list-style: none;
      color: #{$background-color};
      padding: 7px 0px;
      font-size: .875rem; 
      span {
        margin-right: 24px;
      }
    }
  }
 #clock {  
   li {
      display: flex;
      font-size: .875rem;  
      table {
        color: #{$background-color};
      }
      span {
        margin-right: 24px;
        height: 100%;
        line-height: 8.5;
        display: inline-block;
        vertical-align: middle;
      }
   }
 }
 #infos-pratiques {
   background: #{$primary-background-color};
   padding: 2rem;
   margin-top: 2rem;
   border: 1px solid #{$primary-color};
   li {
    color: #{$primary-color!important};
   }
   .title-2 {
     color: #{$secondary-color};
   }
   .infos-content {
     display: flex;
     margin-bottom: 1rem;  
   }
   div {
     margin-left: 1rem; 
     strong {
       color: #{$secondary-color};
     }; 
     p {
       color: #{$text-color};
       margin-bottom: 0;
     } 
   }
 }
 .map-container {
    @media #{$extra-large-and-up} {
      iframe{
        height: 710px;
      }
    }
  }
}
.plan-site {
  li{
    list-style: circle;
    color: #{$secondary-color};
  }
  .sousLi {
    margin-left: 3px;
    padding-left: 3px;
    list-style: inside; 
  }
}
// INPUT NEWSLETTER
// 
.newsletterInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  @media #{$large-and-up}{
    flex-direction: row;
    margin-bottom: 0;
    #js_submit{
      transform: translateX(-140px);
    }
  }
  #input_newsletter {
    background: #fff;
    height: 4rem;
    padding-left: .2rem;
    color: #{$primary-color};
  }
  #js_submit {
    display: inline-block;
    height: auto;
    width: auto;
    padding: $button-padding-top $button-padding-right;
    height: 3rem;
    margin-top: .5rem;
    font: {
        family: inherit;
        size: $button-font-size;
    }
    cursor: pointer; 
    text-decoration: none;
    color: #{$primary-color};
    line-height: $button-line-height;
    text-align: center;
    border: 1px solid #{$primary-color};
    border-radius: $button-radius;
    outline: 0;
    background-color: transparent;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent; // Gets rid of tap active state
    transition: {
        property: color, background-color;
        duration: .3s;
        timing-function: ease-in-out;
    }
    &:hover {
      background: #{$primary-color};
      color:  #{$background-color};
    }
  }
}

// Slick
// ========================================================

.slick-collection {
  position: relative;
  .slick-arrow {
    &::before {
      color: #{$primary-color} !important;
    }
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-collection .slick-next:before, .slick-slicker .slick-next:before{
  font-family: 'Material Icons';
  content:'keyboard_arrow_right';
  font-size: 40px;
  line-height: 1;
  color: white;
  opacity: 0.75;
}

.slick-collection .slick-prev:before, .slick-slicker .slick-prev:before{
  font-family: 'Material Icons';
  content:'keyboard_arrow_left';
  font-size: 40px;
  line-height: 1;
  color: white;
  opacity: 0.75;
}
