.reseaux-sociaux {
  padding: 0;
  width: auto;

  &.black-text {
    svg {
      fill: #000000;
    }
  }
  .white-text &,
  &.white-text {
    svg {
      fill: #ffffff;
    }
  }

  li {
    display: inline-block;
    list-style: none;
    margin: 0 1px;
    border-radius: 6px;
    vertical-align: top;

    &:hover {
      opacity: 0.5;
    }

    a {
      display: block;
      text-decoration: none;
      height: 25px;
      width: 25px;
      padding: 5px 4px;
    }
  }
}